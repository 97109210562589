import Vue from "vue"
import Router from "vue-router"

Vue.use(Router);

export default new Router({
    mode: `history`,
    routes: [
        {
            path: `/`,
            name: `home`,
            component: () => import(`./views/home`)
        },
        {
            path: `/lottery`,
            name: `lottery`,
            component: () => import(`./views/lottery`)
        },
        {
            path: `/slot-machine`,
            name: `slot-machine`,
            component: () => import(`./views/slot-machine`)
        },
        {
            path: `/randomator`,
            name: `randomator`,
            component: () => import(`./views/randomator`)
        }
    ]
})